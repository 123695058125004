import product1 from "../../assets/images/product/img-1.png"
import product2 from "../../assets/images/product/img-2.png"
import product3 from "../../assets/images/product/img-3.png"
import product4 from "../../assets/images/product/img-4.png"
import product5 from "../../assets/images/product/img-5.png"
import product6 from "../../assets/images/product/img-6.png"

const productsData = [
  {
    id: 1,
    image: product1,
    name: "Nike N012 Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Gray",
    islable: true,
    lable: "Trending",
    oldPrice: 280,
    newPrice: 405,
    isOffer: false,
    offer: 0,
    reviews: 234,
    colors: ['dark', 'light', 'primary'],
    shortspecifications: ['High Quality', 'Leather', 'All Sizes available', '4 Different Color'],
    decription : 'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs :[product2,product3,product4],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Gray" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product1, color: "Red" },
      { image: product2, color: "Dark" },
      { image: product3, color: "Purple" },
    ],
  },
  {
    id: 2,
    image: product2,
    name: "Adidas Running Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Black",
    islable: false,
    lable: "",
    oldPrice: 250,
    newPrice: 240,
    isOffer: true,
    offer: 20,
    colors: ['danger', 'dark', 'light'],
    reviews: 100,
    shortspecifications: ['High Quality', 'Leather', 'All Sizes available', '4 Different Color'],
    decription : 'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs :[product1,product3,product4],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Black" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product2, color: "Red" },
      { image: product4, color: "Dark" },
      { image: product3, color: "Purple" },
    ],
  },
  {
    id: 3,
    image: product3,
    name: "Puma P103 Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Purple",
    islable: false,
    lable: "",
    oldPrice: 260,
    newPrice: 250,
    isOffer: false,
    offer: 0,
    colors: ['purple', 'light', 'dark'],
    reviews: 200,
    shortspecifications: ['High Quality', 'Leather', 'All Sizes available', '4 Different Color'],
    decription : 'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs :[product1,product2,product4],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Black" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product3, color: "Red" },
      { image: product4, color: "Dark" },
      { image: product1, color: "Purple" },
    ],
  },
  {
    id: 4,
    image: product4,
    name: "Sports S120 Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Cyan",
    islable: false,
    lable: "",
    oldPrice: 240,
    newPrice: 230,
    isOffer: false,
    offer: 0,
    colors: ['info', 'success'],
    reviews: 150,
    shortspecifications: ['High Quality', 'Leather', 'All Sizes available', '4 Different Color'],
    decription : 'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs :[product1,product2,product3],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Cyan" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product4, color: "Red" },
      { image: product1, color: "Dark" },
      { image: product2, color: "Purple" },
    ],
  },
  {
    id: 5,
    image: product5,
    name: "Adidas AB23 Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Blue",
    islable: false,
    lable: "",
    oldPrice: 240,
    newPrice: 250,
    isOffer: false,
    offer: 0,
    colors: ['dark', 'light', 'primary'],
    reviews: 170,
    shortspecifications: ['High Quality', 'Leather', 'All Sizes available', '4 Different Color'],
    decription : 'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs :[product5,product2,product3],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Blue" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product5, color: "Red" },
      { image: product2, color: "Dark" },
      { image: product3, color: "Purple" },
    ],
  },
  {
    id: 6,
    image: product6,
    name: "Nike N012 Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Gray",
    islable: false,
    lable: "",
    oldPrice: 270,
    newPrice: 260,
    isOffer: true,
    offer: 20,
    colors: ['dark', 'light'],
    reviews: 80,
    shortspecifications: ['High Quality', 'Leather', 'All Sizes available', '4 Different Color'],
    decription : 'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs :[product6,product4,product3],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Gray" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product6, color: "Red" },
      { image: product4, color: "Dark" },
      { image: product3, color: "Purple" },
    ],
  },
]

const comments = [
  {
    id: 1,
    name: "Samuel",
    description:
      "It will be as simple as in fact, it will be Occidental. It will seem like simplified",
    date: "12 July, 2020",
    review: "4.1"
  },
  {
    id: 2,
    name: "Joseph",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit",
    date: "06 July, 2020",
    review: "4.0",
  },
  {
    id: 3,
    name: "Paul",
    description:
      "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet",
    date: "26 June, 2020",
    review: "4.2",
  },
]

const discountData = [
  { label: "Less than 10%", value: 0 },
  { label: "10% or more", value: 10 },
  { label: "20% or more", value: 20 },
  { label: "30% or more", value: 30 },
  { label: "40% or more", value: 40 },
  { label: "50% or more", value: 50 },
]

const colorData = [
  { label: "Black", value: 'dark' },
  { label: "White", value: 'light' },
  { label: "Gray", value: 'secondary' },
  { label: "Blue", value: 'primary' },
  { label: "Green", value: 'success' },
  { label: "Red", value: 'danger' },
  { label: "Yellow", value: 'warning' },
  { label: "Purple", value: 'purple' },
]

const orders = [
  {
    id: 2,
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "2019-10-08",
    total: "$400",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    id: 3,
    orderId: "#SK2541",
    billingName: "Jamal Burnett",
    orderdate: "2019-10-07",
    total: "$380",
    badgeclass: "danger",
    paymentStatus: "Chargeback",
    methodIcon: "fa-cc-visa",
    paymentMethod: "Visa",
  },
  {
    id: 4,
    orderId: "#SK2542",
    billingName: "Juan Mitchell",
    orderdate: "2019-10-06",
    total: "$384",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-paypal",
    paymentMethod: "Paypal",
  },
  {
    id: 5,
    orderId: "#SK2543",
    billingName: "Barry Dick",
    orderdate: "2019-10-05",
    total: "$412",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    id: 6,
    orderId: "#SK2544",
    billingName: "Ronald Taylor",
    orderdate: "2019-10-04",
    total: "$404",
    badgeclass: "warning",
    paymentStatus: "Refund",
    methodIcon: "fa-cc-visa",
    paymentMethod: "Visa",
  },
  {
    id: 7,
    orderId: "#SK2545",
    billingName: "Jacob Hunter",
    orderdate: "2019-10-04",
    total: "$392",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-paypal",
    paymentMethod: "Paypal",
  },
  {
    id: 8,
    orderId: "#SK2546",
    billingName: "William Cruz",
    orderdate: "2019-10-03",
    total: "$374",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fas fa-money-bill-alt",
    paymentMethod: "COD",
  },
  {
    id: 9,
    orderId: "#SK2547",
    billingName: "Dustin Moser",
    orderdate: "2019-10-02",
    total: "$350",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-paypal",
    paymentMethod: "Mastercard",
  },
  {
    id: 10,
    orderId: "#SK2548",
    billingName: "Clark Benson",
    orderdate: "2019-10-01",
    total: "$345",
    badgeclass: "warning",
    paymentStatus: "Refund",
    methodIcon: "fa-cc-paypal",
    paymentMethod: "Visa",
  },
  {
    id: 11,
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "2019-10-08",
    total: "$400",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    id: 12,
    orderId: "#SK2541",
    billingName: "Jamal Burnett",
    orderdate: "2019-10-07",
    total: "$380",
    badgeclass: "danger",
    paymentStatus: "Chargeback",
    methodIcon: "fa-cc-visa",
    paymentMethod: "Visa",
  },
  {
    id: 13,
    orderId: "#SK2542",
    billingName: "Juan Mitchell",
    orderdate: "2019-10-06",
    total: "$384",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-paypal",
    paymentMethod: "Paypal",
  },
  {
    id: 14,
    orderId: "#SK2543",
    billingName: "Barry Dick",
    orderdate: "2019-10-05",
    total: "$412",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    id: 15,
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "2019-10-08",
    total: "$400",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    id: 16,
    orderId: "#SK2541",
    billingName: "Jamal Burnett",
    orderdate: "2019-10-07",
    total: "$380",
    badgeclass: "danger",
    paymentStatus: "Chargeback",
    methodIcon: "fa-cc-visa",
    paymentMethod: "Visa",
  },
  {
    id: 17,
    orderId: "#SK2542",
    billingName: "Juan Mitchell",
    orderdate: "2019-10-06",
    total: "$384",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-paypal",
    paymentMethod: "Paypal",
  },
  {
    id: 18,
    orderId: "#SK2543",
    billingName: "Barry Dick",
    orderdate: "2019-10-05",
    total: "$412",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    id: 19,
    orderId: "#SK2544",
    billingName: "Ronald Taylor",
    orderdate: "2019-10-04",
    total: "$404",
    badgeclass: "warning",
    paymentStatus: "Refund",
    methodIcon: "fa-cc-visa",
    paymentMethod: "Visa",
  },
  {
    id: 20,
    orderId: "#SK2545",
    billingName: "Jacob Hunter",
    orderdate: "2019-10-04",
    total: "$392",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-paypal",
    paymentMethod: "Paypal",
  },
  {
    id: 21,
    orderId: "#SK2546",
    billingName: "William Cruz",
    orderdate: "2019-10-03",
    total: "$374",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fas fa-money-bill-alt",
    paymentMethod: "COD",
  },
  {
    id: 22,
    orderId: "#SK2547",
    billingName: "Dustin Moser",
    orderdate: "2019-10-02",
    total: "$350",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-paypal",
    paymentMethod: "Mastercard",
  },
  {
    id: 23,
    orderId: "#SK2548",
    billingName: "Clark Benson",
    orderdate: "2019-10-01",
    total: "$345",
    badgeclass: "warning",
    paymentStatus: "Refund",
    methodIcon: "fa-cc-paypal",
    paymentMethod: "Visa",
  },
  {
    id: 24,
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "2019-10-08",
    total: "$400",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    id: 25,
    orderId: "#SK2541",
    billingName: "Jamal Burnett",
    orderdate: "2019-10-07",
    total: "$380",
    badgeclass: "danger",
    paymentStatus: "Chargeback",
    methodIcon: "fa-cc-visa",
    paymentMethod: "Visa",
  },
  {
    id: 26,
    orderId: "#SK2542",
    billingName: "Juan Mitchell",
    orderdate: "2019-10-06",
    total: "$384",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-paypal",
    paymentMethod: "Paypal",
  },
  {
    id: 27,
    orderId: "#SK2543",
    billingName: "Barry Dick",
    orderdate: "2019-10-05",
    total: "$412",
    badgeclass: "success",
    paymentStatus: "Paid",
    methodIcon: "fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
]

const cartData = {
  products: [
    {
      id: 1,
      img: "img1",
      name: "Half sleeve T-shirt",
      color: "Maroon",
      price: "450",
      data_attr: 2,
      total: 900,
    },
    {
      id: 2,
      img: "img2",
      name: "Light blue T-shirt",
      color: "Light blue",
      price: "225",
      data_attr: 6,
      total: 225,
    },
    {
      id: 3,
      img: "img3",
      name: "Black Color T-shirt",
      color: "Black",
      price: "152",
      data_attr: 2,
      total: 304,
    },
    {
      id: 4,
      img: "img4",
      name: "Hoodie (Blue)",
      color: "Blue",
      price: "145",
      data_attr: 2,
      total: 290,
    },
    {
      id: 5,
      img: "img5",
      name: "Half sleeve T-Shirt",
      color: "Light orange",
      price: "138",
      data_attr: 8,
      total: 138,
    },
    {
      id: 6,
      img: "img6",
      name: "Green color T-shirt",
      color: "Green",
      price: "152",
      data_attr: 2,
      total: 304,
    },
  ],
  orderSummary: {
    grandTotal: "$ 1,857",
    discount: "$ 157",
    shippingCharge: "$ 25",
    estimatedTax: "$ 19.22",
    total: "$ 1744.22",
  },
}
const customerData = [
  {
    id: 1,
    username: "Stephen Rash",
    phone: "325-250-1106",
    email: "StephenRash@teleworm.us",
    address: "2470 Grove Street Bethpage, NY 11714",
    rating: "4.2",
    walletBalance: "$5,412",
    joiningDate: "2019-10-07",
  },
  {
    id: 2,
    username: "Juan Mays",
    phone: "443-523-4726",
    email: "JuanMays@armyspy.com",
    address: "3755 Harron Drive Salisbury, MD 21875",
    rating: "4.0",
    walletBalance: "$5,632",
    joiningDate: "2019-10-06",
  },
  {
    id: 3,
    username: "Scott Henry",
    phone: "704-629-9535",
    email: "ScottHenry@jourrapide.com",
    address: "3632 Snyder Avenue Bessemer City, NC 2801",
    rating: "4.4",
    walletBalance: "$7,523",
    joiningDate: "2019-10-06",
  },
  {
    id: 4,
    username: "Cody Menendez",
    phone: "701-832-5838",
    email: "CodyMenendez@armyspy.com",
    address: "4401 Findley Avenue Minot, ND 58701",
    rating: "4.1",
    walletBalance: "$6,325",
    joiningDate: "2019-10-05",
  },
  {
    id: 5,
    username: "Jason Merino",
    phone: "706-219-4095",
    email: "JasonMerino@dayrep.com",
    address: "3159 Holly Street Cleveland, GA 30528",
    rating: "3.8",
    walletBalance: "$4,523",
    joiningDate: "2019-10-04",
  },
  {
    id: 6,
    username: "Kyle Aquino",
    phone: "415-232-5443",
    email: "KyleAquino@teleworm.us",
    address: "4861 Delaware Avenue San Francisco, CA 94143",
    rating: "4.0",
    walletBalance: "$5,412",
    joiningDate: "2019-10-03",
  },
  {
    id: 7,
    username: "David Gaul",
    phone: "314-483-4679",
    email: "DavidGaul@teleworm.us",
    address: "1207 Cottrill Lane Stlouis, MO 63101",
    rating: "4.2",
    walletBalance: "$6,180",
    joiningDate: "2019-10-02",
  },
  {
    id: 8,
    username: "John McCray",
    phone: "253-661-7551",
    email: "JohnMcCray@armyspy.com",
    address: "3309 Horizon Circle Tacoma, WA 98423",
    rating: "4.1",
    walletBalance: "$5,2870",
    joiningDate: "2019-10-02",
  },
  {
    id: 9,
    username: "Stephen Rash",
    phone: "325-250-1106",
    email: "StephenRash@teleworm.us",
    address: "2470 Grove Street Bethpage, NY 11714",
    rating: "4.2",
    walletBalance: "$5,412",
    joiningDate: "2019-10-07",
  },
  {
    id: 10,
    username: "Juan Mays",
    phone: "443-523-4726",
    email: "JuanMays@armyspy.com",
    address: "3755 Harron Drive Salisbury, MD 21875",
    rating: "4.0",
    walletBalance: "$5,632",
    joiningDate: "2019-10-06",
  },
  {
    id: 11,
    username: "Scott Henry",
    phone: "704-629-9535",
    email: "ScottHenry@jourrapide.com",
    address: "3632 Snyder Avenue Bessemer City, NC 2801",
    rating: "4.4",
    walletBalance: "$7,523",
    joiningDate: "2019-10-06",
  },
  {
    id: 12,
    username: "Cody Menendez",
    phone: "701-832-5838",
    email: "CodyMenendez@armyspy.com",
    address: "4401 Findley Avenue Minot, ND 58701",
    rating: "4.1",
    walletBalance: "$6,325",
    joiningDate: "2019-10-05",
  },
  {
    id: 13,
    username: "Jason Merino",
    phone: "706-219-4095",
    email: "JasonMerino@dayrep.com",
    address: "3159 Holly Street Cleveland, GA 30528",
    rating: "3.8",
    walletBalance: "$4,523",
    joiningDate: "2019-10-04",
  },
]

const shops = [
  {
    id: 1,
    firstLetter : "M",
    color: "primary",
    name: "Nedick's",
    subtitle:"ClWayne McClain",
    email : "WayneMcclain@gmail.com",
    product: 86,
    balance: "12,456",
    date: "07/10/2021",
  },
  {
    id: 2,
    firstLetter : "B",
    color: "warning",
    name: "Brendle's",
    subtitle:"KaDavid Marshall",
    email : "Davidmarshall@gmail.com",
    product: 82,
    balance: "34,523",
    date: "12/10/2021",
  },
  {
    id: 3,
    firstLetter : "K",
    color: "danger",
    name: "Tech Hifi",
    subtitle:"MaKatia Stapleton",
    email : "Katiastapleton@gmail.com",
    product: 75,
    balance: "63,265",
    date: "14/11/2021",
  },
  {
    id: 4,
    firstLetter : "P",
    color: "success",
    name: "Packer",
    subtitle : "Mae Rankin",
    email : "Maerankingmail.com",
    product: 62,
    balance: "42,652",
    date: "14/11/2021",
  },
  {
    id: 5,
    firstLetter : "L",
    color: "info",
    name: "Lafayette",
    subtitle : "Andrew Bivens",
    email : "Andrewbivens@gmail.com",
    product: 55,
    balance: "52,652",
    date: "15/11/2021",
  },
  {
    id: 6,
    firstLetter : "B",
    color: "dark",
    name: "Tech Hifi",
    subtitle : "John McLeroy",
    email : "JohnmcLeroy@gmail.com",
    product: 53,
    balance: "12,523",
    date: "20/11/2021",
  },
  {
    id: 7,
    firstLetter : "K",
    color: "dark",
    name: "Packer",
    subtitle : "Katia Stapleton",
    email : "Katiastapleton@gmail.com",
    product: 66,
    balance: "63,523",
    date: "23/11/2021",
  },
  {
    id: 8,
    firstLetter : "P",
    color: "primary",
    name: "Packer",
    subtitle : "Mae Rankin",
    email : "Maerankingmail.com",
    product: 62,
    balance: "42,652",
    date: "14/11/2021",
  },
  {
    id: 9,
    firstLetter : "L",
    color: "success",
    name: "Lafayette",
    subtitle : "Andrew Bivens",
    email : "Andrewbivens@gmail.com",
    product: 55,
    balance: "52,652",
    date: "15/11/2021",
  },
  {
    id: 9,
    firstLetter : "B",
    color: "success",
    name: "Tech Hifi",
    subtitle : "John McLeroy",
    email : "JohnmcLeroy@gmail.com",
    product: 53,
    balance: "12,523",
    date: "20/11/2021",
  },
  {
    id: 9,
    firstLetter : "K",
    color: "success",
    name: "Packer",
    subtitle : "Katia Stapleton",
    email : "Katiastapleton@gmail.com",
    product: 66,
    balance: "63,523",
    date: "23/11/2021",
  },
]

export {
  productsData,
  comments,
  discountData,
  colorData,
  orders,
  shops,
  customerData,
  cartData,
}
